/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import './assets/scss/main.scss';
@import 'assets/scss/inputs/_input.sass';
@import 'assets/scss/tabs/_tabs.sass';
@import 'assets/scss/carousel/_carousel.scss';
@import '@ng-select/ng-select/scss/default.theme.scss';
@import 'material-icons/iconfont/material-icons.scss';

// .flex-row {
//     display: flex !important;
//     flex-direction: row !important;
// }

// .flex-column {
//     display: flex !important;
//     flex-direction: column !important;
// }

.center {
  justify-content: center !important;
  align-items: center !important;
}

.align-center {
  align-items: center !important;
}

.j-space-between {
  justify-content: space-between !important;
}

.space-even {
  justify-content: space-evenly !important;
  align-content: space-around !important;
}

.break-row-100 {
  padding-bottom: 1em;
}

.w-100 {
  width: 100%;
}

.break-column-50 {
  padding-left: 0.5em;
}

::ng-deep.overlay {
  background-color: initial;
}

.b-row .btn:not(:first-child) {
  margin-left: 0.5em;
}

.toast-container {
  top: 70px !important;
}

.toast-center-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

  &>div {
    box-shadow: unset !important;
  }

  &>div:hover {
    box-shadow: unset !important;
    cursor: unset !important;
  }

  .toast-resend-code {
    color: #ffffffb3 !important;
    background-color: #323232 !important;
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f !important;
  }
}

.sortable-container {
  border: unset !important;
}

.sortable-container .sortable-list .active {
  border-radius: 8px !important;
  color: unset !important;
}

.sortable-container .sortable-header {
  background: unset !important;
  background-image: unset !important;
  border-bottom: unset !important;
}

.btn-primary {
  background-color: #166426 !important;
  border-color: #166426 !important;
}

.btn-success {
  background-color: #166426 !important;
  border-color: #166426 !important;
}

.form-control:focus {
  border-color: #166426 !important;
}

.breadcrumb-item {

  a {
    color: #166426 !important;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #166426 !important;
  border-top: solid 2px #166426 !important;
}

.ngx-pagination .current {
  background: #166426 !important;
}

.card-primary:not(.card-outline)>.card-header {
  background-color: #166426 !important;
}

.card-info:not(.card-outline)>.card-header {
  background-color: #396a43 !important;
}

.btn-link {
  color: #166426 !important;
}

.ngx-slider .ngx-slider-pointer {
  background-color: #166426 !important;
}

.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: white !important;
}

.btn-outline-primary {
  color: #166426 !important;
  border-color: #166426 !important;
}

.bold-text {
  color: #000 !important;
  font-weight: bold;
}

.bold-text-primary {
  color: #166426 !important;
  font-weight: bold;
}

.btn-outline-primary:hover {
  color: white !important;
  border-color: #166426 !important;
  background-color: #166426 !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #166426 !important;
  background-color: #166426 !important;
}

.custom-control {
  a {
    color: #166426 !important;
  }
}

.bg-info {
  background-color: #396a43 !important;
}

.card {
  border: unset !important;
}

.hover-db {
  color: white !important;
  background-color: #166426 !important;
}

.dropdown-item {
  cursor: pointer !important;
}

.wrapper .content-wrapper {
  min-height: calc(100vh - 5rem) !important;
  overflow-x: hidden !important;
}

.snack {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.snackbar-error {
  background-color: red !important;
  color: white !important;
}

.snackbar-success {
  background-color: #166426 !important;
  color: white !important;
}

.snackbar-info {
  background-color: blue !important;
  color: white !important;
}

.snackbar-warning {
  background-color: orange !important;
  color: white !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button:focus {
  outline: unset !important;
}


/*-------- 1.2 Carousel --------*/
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.ml-4rem{
  margin-right: 4rem;
}

.mt-3rem{
  margin-top: 3rem;
}