.icon-acoes
    cursor: pointer


.float-right
    float: right

a.hover-white:hover span.text-white 
  color: black

.row.vertical-divider 
  overflow: hidden

.row.vertical-divider > div[class^="col-"] 
  border-right: 1px solid rgba(0, 0, 0, 0.1)

.row.vertical-divider div[class^="col-"]:first-child 
  border-left: none

.row.vertical-divider div[class^="col-"]:last-child 
  border-right: none
