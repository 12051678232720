.carrousel-previous-next {
  width: 28px;
  height: 28px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  opacity: 0.5;
}

.carrousel-previous-next:hover {
  opacity: 1;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  filter: invert(1);
}

.carousel-gallery .carousel-control-next-icon, .carousel-gallery .carousel-control-prev-icon {
  filter: invert(1);
  width: 28px;
  height: 28px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 55px;
  background-size: 50%;
}

.carousel-gallery .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-gallery .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-gallery .carousel-inner {
  border-radius: 8px;
}

.carousel-gallery:focus {
  outline: unset;
}

.carousel-gallery .carousel-indicators {
  margin: 0px 35px 0px 35px !important;
}

.ngx-gallery-arrow {
  width: 28px !important;
  height: 28px !important;
  background: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  opacity: 0.5 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ngx-gallery-arrow:hover {
  opacity: 1 !important;
}

.carousel-indicators li {
  background-color: #555 !important;
}

.ngx-gallery-thumbnails .ngx-gallery-thumbnail.ngx-gallery-active {
  border: 1px solid #166426 !important;
}

.ngx-gallery-thumbnails .ngx-gallery-thumbnail {
  border: unset !important;
}

.owl-carousel.owl-theme.owl-carousel-fare .owl-dots {
  display: block;
}

.owl-theme.owl-carousel-fare .owl-dots .owl-dot.active span, .owl-theme.owl-carousel-fare .owl-dots .owl-dot:hover span {
  background: #166426 !important;
}

.owl-theme.owl-carousel-fare .owl-dots .owl-dot span {
  width: 13px !important;
  height: 4px !important;
  border-radius: 30px;
}

.carousel-rooms .carousel-inner .carousel-item img {
  transform: unset !important;
}

.carousel-rooms {
  width: 100% !important;
}
