.blend-right
  border-top-right-radius: 0 !important
  border-bottom-right-radius: 0 !important

.blend-left
  border-top-left-radius: 0 !important
  border-bottom-left-radius: 0 !important

.carousel-item.active, .carousel-item-next, .carousel-item-prev
  display: contents !important

.item.active
  width: 33.33%
  height: 100%
