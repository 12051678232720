@import 'basics/base';

// .ng-invalid.ng-dirty:not(form), .ng-invalid:not(form).form-submitted {
//   border: solid 2px lightcoral;
// }

.b-row .btn:not(:first-child) {
  padding-left: 1em;
}

.circle {
  background-color: #aaa;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  overflow: hidden;
  position: relative;

  &.form-image{
      width: 400px;
      height: 400px;

      img{
          width: 400px;
          height: 400px;
          left: 0px;
      }
  }
}

.circle img {
  position: absolute;
  bottom: 0px;
  width: 189px;
  height: 160px;
  left: -15px;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-db {
  background-color: #23317a;
}

a.hover-white:hover span.text-white {
  color: black;
}
    
a:not([href]):hover span.text-white {
  color: black !important;
}
.modal-dialog .overlay {
  background-color: unset !important;
}

.square {
  background-color: #aaa;
  width: 300px;
  height: 300px;
  overflow: hidden;
  position: relative;

  &.form-image{
      width: 300px;
      height: 300px;

      img{
          width: 300px;
          height: 300px;
          left: 0px;
      }
  }
}

.square img {
  position: absolute;
  bottom: 0px;
  width: 300px;
  height: 300px;
  left: 0px;
}

.card-rounded-10 {
  border-radius: 10px !important;
  background-color: #fdfdfd !important;
}

.nav-item.nav-link:not(active) {
  border-color: #e9ecef #e9ecef #dee2e6;
}

